import { JHipsterException } from "../types/loader";

export type Campaign = {
  category: string;
  copy: string;
  id: string;
  imageUrl: string;
  listingRef: string;
  name: string;
  scope: "SPECIFIC_MEMBERS" | "WHOLE_TEAM";
  teamMembers: string[];
  teamRef: string;
  type: "CHANNEL" | "FORM";
};

const baseUrl = import.meta.env.VITE_APP_API as string;

export async function getCampaign(
  campaignRef: string | undefined
  // _request: Request
): Promise<Campaign | undefined> {
  const response = await fetch(`${baseUrl}/api/campaign-form/${campaignRef}`);

  const body: Campaign = await response.json();

  if (response.ok) {
    if (body.id) {
      return body;
    } else {
      return Promise.reject(new Error(`No campaign with id ${campaignRef}`));
    }
  } else {
    const e = body as unknown as JHipsterException;
    const errors = [e?.message, e?.detail];
    const error = new Error(errors.filter((v) => v).join("\n") ?? e);
    return Promise.reject(error);
  }
}
