export default function Root() {
  return (
    <main className="relative min-h-screen bg-white sm:flex sm:items-center sm:justify-center">
      <div className="relative sm:pb-16 sm:pt-8">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="shadow-x relative sm:overflow-hidden sm:rounded-2xl">
            <div className="absolute inset-0">
              <div className="inset-0 bg-[color:rgba(255,56,56,0.5)] mix-blend-multiply" />
            </div>
            <div className="relative px-4 pt-16 pb-8 sm:px-6 sm:pt-24 sm:pb-14 lg:px-8 lg:pb-20 lg:pt-32">
              <h1 className="text-center text-6xl font-extrabold tracking-tight sm:text-8xl lg:text-9xl">
                <span className="text-black-500 block drop-shadow-md">
                  SpaceIt<sup>&trade;</sup>
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
