import { LoaderFunction, useLoaderData } from "react-router";
import attribution from "@/assets/attribution@2x.png";
import { Button } from "@/components/ui/button";
import logo from "@/assets/douglaselliman.png";
import { getOpenHouseCampaign } from "@/lib/api/get-open-house-campaign";
import invariant from "tiny-invariant";

type LoaderData = {
  openHouse: NonNullable<Awaited<ReturnType<typeof getOpenHouseCampaign>>>;
  view: string | null;
};

export const loader: LoaderFunction = async ({ request }: any) => {
  const url = new URL(request.url);
  const query = url.searchParams.get("q");
  const view = url.searchParams.get("v");
  invariant(query, "not found");

  const openHouse = await getOpenHouseCampaign(query);

  if (!openHouse) {
    throw new Response("Not Found", { status: 404 });
  }

  return { openHouse, view };
};

export default function Confirm() {
  const { openHouse, view } = useLoaderData() as unknown as LoaderData;

  const agentLink = `https://elliman.com/${openHouse.externalUserId}`;

  const propertyLink = `https://elliman.com/${openHouse.property?.externalListingId}`;

  return (
    <main className="bg-sky-50">
      <div className="container max-w-2xl py-4">
        <div className="mb-4">
          <img width={350} alt="Douglas Elliman" src={logo} />
        </div>
        <div>
          <h1 className="text-2xl font-bold">Confirmation</h1>
          <p className="text-base mb-4">
            Thank you for visiting {openHouse.property.name}.
          </p>
        </div>
        <div className="text-center mb-4">
          {openHouse.property?.externalAvailableOnWeb && (
            <Button className="text-base h-auto" asChild>
              <a href={propertyLink}>See more details on this property</a>
            </Button>
          )}
        </div>
        {view === "l" && (
          <div className="text-center mb-4">
            <Button className="text-base h-auto" asChild>
              <a href={agentLink}> See other properties from this agent</a>
            </Button>
          </div>
        )}
      </div>
      <div className="text-right p-3">
        <img
          width="177"
          alt="Powered by SpaceIt"
          src={attribution}
          className="inline"
        />
      </div>
    </main>
  );
}
