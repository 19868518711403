import { useFormContext } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  // SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import StyledSelectTrigger from "../components/common/styled-select-trigger";
import RequiredMarker from "../components/common/required-marker";
import AgentContactInfo from "@/features/agent-contact-info";

export function BrokerRepFields() {
  const { control, setValue, watch } = useFormContext<any>();

  const representedByBroker$ = watch("representedByBroker");

  const representedHandler = (e: string | undefined) => {
    if (!e || e === "false") {
      setValue("brokerName", "");
      setValue("brokerCompany", "");
      setValue("brokerEmailAddress", "");
      setValue("brokerPhoneNumber", "");
      setValue("brokerPhoneType", "");
    }
  };

  return (
    <>
      <FormField
        control={control}
        name="representedByBroker"
        render={({ field, fieldState }) => {
          return (
            <FormItem className="mb-4">
              <FormLabel className="text-inherit">
                <RequiredMarker>
                  Are you represented by a broker?
                </RequiredMarker>
              </FormLabel>
              <Select
                value={field.value}
                onValueChange={(v) => {
                  representedHandler(v);
                  field.onChange(v);
                }}
              >
                <FormControl>
                  <StyledSelectTrigger {...field} {...fieldState}>
                    <SelectValue />
                  </StyledSelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="">Select</SelectItem>
                  <SelectItem value="true">Yes</SelectItem>
                  <SelectItem value="false">No</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage className="font-normal" />
            </FormItem>
          );
        }}
      />

      {representedByBroker$ === "true" && (
        <div className="border-2 p-4 rounded-md mb-4">
          <div className="font-medium text-lg">
            Please provide your broker&apos;s information.
          </div>
          <AgentContactInfo />
        </div>
      )}
    </>
  );
}
