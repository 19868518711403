import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";

import { useForm } from "react-hook-form";
import RequiredMarker from "@/components/common/required-marker";
import StyledInput from "@/components/common/styled-input";
import {
  Dialog,
  DialogContent,
  // DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { FormEvent, useState } from "react";
import SearchCriteria from "@/features/search-criteria";

type Props = {
  append: (values: any) => void;
};

export default function AddTenant({ append }: Props) {
  const [open, setOpen] = useState(false);

  const form = useForm<any>({
    defaultValues: {
      tenantName: "",
      sqftMax: "",
      sqftMin: "",
      priceMax: "",
      priceMin: "",
      bedrooms: "",
      bathrooms: "",
    },
    resolver: yupResolver(
      yup.object({
        tenantName: yup.string().required("Required"),
      })
    ),
  });

  const { control, handleSubmit, reset } = form;

  const onSubmit = (data: any) => {
    const model = {
      ...data,
    };
    ["priceMax", "priceMin", "sqftMax", "sqftMin"].forEach((name) => {
      const value = data[name] as string | null | undefined;
      if (value) {
        const replace = value?.replace(/[^\d.]+/g, "");
        Object.assign(model, { [name]: replace });
      }
    });
    append(model);
    setOpen(false);
    reset();
  };

  const formHandler = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    return handleSubmit(onSubmit)();
  };

  const onOpenChange = (v: boolean) => {
    if (!v) {
      reset();
    }
    setOpen(v);
  };

  // useEffect(() => {
  //   return () => console.log("unmount");
  // }, []);

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogTrigger asChild>
          <Button variant="outline" size="default">
            Add Client
          </Button>
        </DialogTrigger>
        <DialogContent className={"overflow-y-scroll max-h-screen"}>
          <DialogHeader>
            <DialogTitle>Add Clients</DialogTitle>
            {/* <DialogDescription></DialogDescription> */}
          </DialogHeader>
          <section>
            <Form {...form}>
              <form id="add" onSubmit={formHandler}>
                <FormField
                  control={control}
                  name="tenantName"
                  render={({ field, fieldState }) => (
                    <FormItem className="mb-4">
                      <FormLabel className="text-inherit">
                        <RequiredMarker>
                          Client&apos;s Name
                        </RequiredMarker>
                      </FormLabel>
                      <FormControl>
                        <StyledInput {...field} {...fieldState} />
                      </FormControl>
                      <FormMessage className="font-normal" />
                    </FormItem>
                  )}
                />
                <SearchCriteria />
                <div className="text-right">
                  <Button form="add" className="flex-1" type="submit">
                    Add Client
                  </Button>
                </div>
              </form>
            </Form>
          </section>
        </DialogContent>
      </Dialog>
    </>
  );
}
