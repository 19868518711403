export function ContactInfo() {
  return (
    <div className="text-sm mb-4">
      <div>If you have any issues submitting this form, please contact</div>
      <div>
        <a
          className="text-cyan-700 hover:underline"
          href="mailto:customer-service@spaceitproperty.elliman.com"
        >
          customer-service@spaceitproperty.elliman.com
        </a>
      </div>
    </div>
  );
}
