import attribution from "@/assets/attribution@2x.png";
import { useLoaderData } from "react-router";
import { LoaderData } from "./loader";
import { Link } from "react-router-dom";

export default function Campaign() {
  const { campaign } = useLoaderData() as unknown as LoaderData;

  return (
    <main className="container max-w-2xl my-4">
      <div className="mb-4">
        <img src={campaign?.imageUrl} alt="" width="100%" />
      </div>
      <p className="text-sm">{campaign.copy}</p>
      <div className="text-center underline text-blue-500">
        <Link
          to={{
            pathname: "new",
          }}
        >
          Guest Sign In
        </Link>
      </div>
      <div className="text-right my-4 p-3">
        <img
          width="177"
          alt="Powered by SpaceIt"
          src={attribution}
          className="inline"
        />
      </div>
    </main>
  );
}
