import { getShortProperty } from "@/lib/api/get-short-property";
import { LoaderParams } from "@/lib/types/loader";
import invariant from "tiny-invariant";

export type LoaderData = {
  data: NonNullable<Awaited<ReturnType<typeof getShortProperty>>>;
};

export const loader = async ({ params }: LoaderParams) => {
  invariant(params?.id, "id not found");

  const data = await getShortProperty(params.id);
  return <LoaderData>{ data };
};
