import { useFormContext } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import { BrokerRepFields } from "../../features/broker-rep-fields";
import StyledSelectTrigger from "../common/styled-select-trigger";
import RequiredMarker from "../common/required-marker";
import AdditionalTenants from "@/features/additional-tenants";
import CommentField from "@/features/comment-field";
import SearchCriteria from "@/features/search-criteria";
import { LeadContactInfo } from "@/features/lead-contact-info";
import TenantNameField from "@/features/tenant-name-field";

export default function FormGroup() {
  const { control, getValues, watch, reset } = useFormContext();

  const [leadType$, representedByBroker$] = watch([
    "leadType",
    "representedByBroker",
  ]);

  const handleLeadTypeChange = (v: string | undefined) => {
    const values = getValues();

    reset({
      ...values,
      bedrooms: "",
      bathrooms: "",
      representedByBroker: "",
      leadType: v,
      priceMax: "",
      priceMin: "",
      propertyType: "",
      sqftMax: "",
      sqftMin: "",
      brokerName: "",
      brokerCompany: "",
      brokerPhoneNumber: "",
      brokerPhoneType: "",
      brokerEmailAddress: "",
      tenantName: "",
      additionalTenants: [],
      company: "",
    });
    // campaignRef,
    // name: "",
    // emailAddress: "",
    // phoneNumber: "",
    // phoneType: "",
    // textingAllowed: false,
    // comments: "",
  };

  return (
    <>
      <FormField
        control={control}
        name="leadType"
        render={({ field, fieldState }) => {
          return (
            <FormItem className="mb-4">
              <FormLabel className="text-inherit">
                <RequiredMarker>
                  Are you a licensed real estate agent or broker?
                </RequiredMarker>
              </FormLabel>
              <Select
                value={field.value}
                onValueChange={(v) => {
                  handleLeadTypeChange(v);
                  field.onChange(v);
                }}
              >
                <FormControl>
                  <StyledSelectTrigger {...field} {...fieldState}>
                    <SelectValue />
                  </StyledSelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="">Select</SelectItem>
                  <SelectItem value="REPRESENTATIVE">Yes</SelectItem>
                  <SelectItem value="TENANT">No</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage className="font-normal" />
            </FormItem>
          );
        }}
      />
      <LeadContactInfo />
      {leadType$ === "REPRESENTATIVE" && (
        <>
          <TenantNameField />
          <SearchCriteria />
          <AdditionalTenants />
        </>
      )}

      {leadType$ === "TENANT" && (
        <>
          <BrokerRepFields />
          {representedByBroker$ === "false" && <SearchCriteria />}
        </>
      )}
      <CommentField />
    </>
  );
}
