import * as yup from "yup";
import "yup-phone-lite";

const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[+a-zA-Z0-9_-]+(?:\.[+a-zA-Z0-9_-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const regx = /[^\d.]+/g;

export const leadFormSchema = yup.object().shape(
  {
    bedrooms: yup.string().notRequired(),
    bathrooms: yup.string().notRequired(),
    comments: yup.string().notRequired(),
    priceMax: yup.string().notRequired(),
    priceMin: yup.string().notRequired(),
    propertyType: yup.string(),
    textingAllowed: yup.mixed().notRequired(),
    campaignRef: yup.string().required(),
    name: yup.string().required("Please enter your name."),
    emailAddress: yup
      .string()
      .matches(EMAIL_REGEXP, {
        excludeEmptyString: true,
        message: "Please enter a valid email",
      })
      .when("phoneNumber", {
        is: "",
        then: (schema) => schema.required("Please enter a valid email."),
      }),
    phoneNumber: yup
      .string()
      .phone("US", "Please enter a valid phone number.")
      .when("emailAddress", {
        is: "",
        then: (schema) =>
          schema
            .phone("US", "Please enter a valid phone number.")
            .required("Please enter a phone number."),
      }),
    phoneType: yup.string().when("phoneNumber", {
      is: (value: string) => value,
      then: (schema) => schema.required("Please select a phone type."),
    }),
    sqftMax: yup.string().when("sqftMin", {
      is: (value: string) => value,
      then: (schema) =>
        schema.test(
          "test-max",
          "Max square feet must be greater than min square feet",
          (value, context: any) => {
            if (value) {
              const min = parseFloat(
                `${context.parent.sqftMin}`.replace(regx, "")
              );
              const max = parseFloat(`${value}`.replace(regx, ""));
              return min < max;
            }
            return true;
          }
        ),
    }),
    sqftMin: yup.string().when("sqftMax", {
      is: (value: string) => value,
      then: (schema) =>
        schema.test(
          "test-min",
          "Min square feet must be less than max square feet",
          (value, context: any) => {
            if (value) {
              const min = parseFloat(`${value}`.replace(regx, ""));
              const max = parseFloat(
                `${context.parent.sqftMax}`.replace(regx, "")
              );
              return min < max;
            }
            return true;
          }
        ),
    }),
  },
  [
    ["emailAddress", "phoneNumber"],
    ["sqftMin", "sqftMax"],
  ]
);

export const baseSchema = yup.object().shape(
  {
    leadType: yup.string().required("Required"),
    name: yup.string().required("Please enter your name."),
    company: yup.string().when("leadType", {
      is: (value: string) => value === "REPRESENTATIVE",
      then: (s) => s.required("Required"),
    }),
    emailAddress: yup
      .string()
      .matches(EMAIL_REGEXP, {
        excludeEmptyString: true,
        message: "Please enter a valid email",
      })
      .when("phoneNumber", {
        is: "",
        then: (schema) => schema.required("Please enter a valid email."),
      }),
    phoneNumber: yup
      .string()
      .phone("US", "Please enter a valid phone number.")
      .when("emailAddress", {
        is: "",
        then: (schema) =>
          schema
            .phone("US", "Please enter a valid phone number.")
            .required("Please enter a phone number."),
      }),
    phoneType: yup.string().when("phoneNumber", {
      is: (value: string) => value,
      then: (schema) => schema.required("Please select a phone type."),
    }),
    textingAllowed: yup.mixed().notRequired(),
    tenantName: yup.string().when("leadType", {
      is: (value: string) => value === "REPRESENTATIVE",
      then: (s) => s.required("Required"),
    }),
    campaignRef: yup.string().required(),
    additionalTenants: yup.array(),
    openHouseSelectionCampaignRef: yup.string().optional(),
  },
  [["emailAddress", "phoneNumber"]]
);

export const brokerRepSchema = yup.object().shape(
  {
    brokerName: yup.string(),
    brokerCompany: yup.string(),
    brokerEmailAddress: yup.string().matches(EMAIL_REGEXP, {
      excludeEmptyString: true,
      message: "Please enter a valid email",
    }),
    brokerPhoneNumber: yup
      .string()
      .phone("US", "Please enter a valid phone number."),
    brokerPhoneType: yup.string().when("brokerPhoneNumber", {
      is: (value: string) => value,
      then: (schema) => schema.required("Please select a phone type."),
    }),
  },
  [["brokerEmailAddress", "brokerPhoneNumber"]]
);

export const tenantSchema = yup.object().shape(
  {
    representedByBroker: yup.string().when("leadType", {
      is: (value: string) => value === "TENANT",
      then: (s) => s.required("Required"),
    }),
  },
  [["emailAddress", "phoneNumber"]]
);

export const searchCriteriaSchema = yup.object().shape(
  {
    bedrooms: yup.string().notRequired(),
    bathrooms: yup.string().notRequired(),
    comments: yup.string().notRequired(),
    priceMax: yup.string().notRequired(),
    priceMin: yup.string().notRequired(),
    propertyType: yup.string(),
    sqftMax: yup.string().when("sqftMin", {
      is: (value: string) => value,
      then: (schema) =>
        schema.test(
          "test-max",
          "Max square feet must be greater than min square feet",
          (value, context: any) => {
            if (value) {
              const min = parseFloat(
                `${context.parent.sqftMin}`.replace(regx, "")
              );
              const max = parseFloat(`${value}`.replace(regx, ""));
              return min < max;
            }
            return true;
          }
        ),
    }),
    sqftMin: yup.string().when("sqftMax", {
      is: (value: string) => value,
      then: (schema) =>
        schema.test(
          "test-min",
          "Min square feet must be less than max square feet",
          (value, context: any) => {
            if (value) {
              const min = parseFloat(`${value}`.replace(regx, ""));
              const max = parseFloat(
                `${context.parent.sqftMax}`.replace(regx, "")
              );
              return min < max;
            }
            return true;
          }
        ),
    }),
  },
  [["sqftMin", "sqftMax"]]
);
