const baseUrl = import.meta.env.VITE_APP_API as string;

export const cancelAttendance = async (leadRef: string) => {
  const url = `${baseUrl}/api/property-form/cancel-open-house-attend/${leadRef}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};
