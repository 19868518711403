import React from "react";
import { useFormContext } from "react-hook-form";
import { useNumericFormat } from "react-number-format";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import InputPatternFormat from "../common/input-pattern-format";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { RadioGroup } from "@/components/ui/radio-group";
import { BATHROOMS, BEDROOMS, PRICES } from "@/lib/constants";
import RadioButtonCards from "@/components/common/radio-button-cards";
import InputNumericFormat from "@/components/common/input-numeric-format";
import { Textarea } from "@/components/ui/textarea";
import StyledInput from "../common/styled-input";
import StyledSelectTrigger from "../common/styled-select-trigger";
import RequiredMarker from "../common/required-marker";

export default function GuestFormGroup() {
  const {
    control,
    setValue,
    watch,
    trigger,
    formState: { isSubmitted },
  } = useFormContext<any>();

  const [phoneType$, priceMax$, priceMin$] = watch([
    "phoneType",
    "priceMax",
    "priceMin",
  ]);

  const { format } = useNumericFormat({
    thousandSeparator: true,
    prefix: "$",
  });

  const priceRanges = React.useMemo(
    () =>
      PRICES.map((n) => ({
        id: `${n}`,
        label: format!(`${n}`),
      })),
    [format]
  );

  const validateEmail = () => {
    if (isSubmitted) {
      trigger("emailAddress");
    }
  };

  const validatePhoneNumber = () => {
    if (isSubmitted) {
      trigger("phoneNumber");
    }
  };

  React.useEffect(() => {
    if (priceMin$ && priceMax$) {
      const [min, max] = [parseInt(priceMin$, 10), parseInt(priceMax$, 10)];
      if (min > max) {
        setValue("priceMin", max);
        setValue("priceMax", min);
      }
    }
  }, [priceMin$, priceMax$, setValue]);

  return (
    <div>
      <FormField
        control={control}
        name="name"
        render={({ field, fieldState }) => (
          <FormItem className="mb-4">
            <FormLabel className="text-inherit">
              <RequiredMarker>Name</RequiredMarker>
            </FormLabel>
            <FormControl>
              <StyledInput {...field} {...fieldState} />
            </FormControl>
            <FormMessage className="font-normal" />
          </FormItem>
        )}
      />

      <div className="text-xs text-yellow-600 pb-2">
        Only one contact method required, an email or phone number.
      </div>
      
      {/* PHONE */}
      <div className="md:flex gap-4">
        <div className="grow basis-0">
          <FormField
            control={control}
            name="phoneNumber"
            render={({ field, fieldState }) => (
              <FormItem className="mb-4">
                <FormLabel className="text-inherit">
                  <RequiredMarker className="text-yellow-600">
                    Phone Number
                  </RequiredMarker>
                </FormLabel>
                <FormControl>
                  <InputPatternFormat
                    format="+1 (###) ###-####"
                    {...field}
                    {...fieldState}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      field.onChange(e.target.value);
                      validateEmail();
                    }}
                  />
                </FormControl>
                <FormMessage className="font-normal" />
              </FormItem>
            )}
          />
        </div>
        <div className="grow basis-0">
          <FormField
            control={control}
            name="phoneType"
            render={({ field, fieldState }) => {
              return (
                <FormItem className="mb-4">
                  <FormLabel className="text-inherit">
                    <RequiredMarker className="text-yellow-600">
                      Phone Type
                    </RequiredMarker>
                  </FormLabel>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <StyledSelectTrigger {...field} {...fieldState}>
                        <SelectValue placeholder="Select" />
                      </StyledSelectTrigger>
                      {/* <SelectTrigger className="bg-neutral-200 shadow-none focus:ring-black focus:ring-2">
                        <SelectValue placeholder="Select" />
                      </SelectTrigger> */}
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="MOBILE">Mobile</SelectItem>
                      <SelectItem value="HOME">Home</SelectItem>
                      <SelectItem value="OFFICE">Office</SelectItem>
                      <SelectItem value="OTHER">Other</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage className="font-normal" />
                </FormItem>
              );
            }}
          />
        </div>
      </div>

      {/* TEXT OPT */}
      {phoneType$ === "MOBILE" && (
        <FormField
          control={control}
          name="textingAllowed"
          render={({ field }) => (
            <FormItem className="mb-4 flex flex-row items-center space-x-2 space-y-0 ">
              <FormControl>
                <Checkbox
                  className="w-5 h-5 border-2 shadow-none"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel className="text-inherit">
                OK to receive text message from agent
              </FormLabel>
              <FormMessage className="font-normal" />
            </FormItem>
          )}
        />
      )}

      {/* EMAIL */}
      <FormField
        control={control}
        name="emailAddress"
        render={({ field, fieldState }) => (
          <FormItem className="mb-4">
            <FormLabel className="text-inherit">
              <RequiredMarker className="text-yellow-600">Email</RequiredMarker>
            </FormLabel>
            <FormControl>
              <StyledInput
                {...field}
                {...fieldState}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e.target.value);
                  validatePhoneNumber();
                }}
              />
            </FormControl>
            <FormMessage className="font-normal" />
          </FormItem>
        )}
      />

      <div className="flex gap-4">
        <div className="grow basis-0">
          <FormField
            control={control}
            name="sqftMin"
            render={({ field, fieldState }) => (
              <FormItem className="mb-4">
                <FormLabel className="text-inherit">Min Square Feet</FormLabel>
                <FormControl>
                  <InputNumericFormat
                    thousandSeparator
                    inputMode="numeric"
                    placeholder="No Min"
                    {...field}
                    {...fieldState}
                  />
                </FormControl>
                <FormMessage className="font-normal" />
              </FormItem>
            )}
          />
        </div>
        <div className="grow basis-0">
          <FormField
            control={control}
            name="sqftMax"
            render={({ field, fieldState }) => (
              <FormItem className="mb-4">
                <FormLabel className="text-inherit">Max Square Feet</FormLabel>
                <FormControl>
                  <InputNumericFormat
                    thousandSeparator
                    inputMode="numeric"
                    placeholder="No Max"
                    {...field}
                    {...fieldState}
                  />
                </FormControl>
                <FormMessage className="font-normal" />
              </FormItem>
            )}
          />
        </div>
      </div>

      {/* PRICE */}
      <div className="flex gap-4">
        <div className="grow basis-0">
          {/* PRICE MIN*/}
          <FormField
            control={control}
            name="priceMin"
            render={({ field, fieldState }) => {
              return (
                <FormItem className="mb-4">
                  <FormLabel className="text-inherit">Min Price</FormLabel>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <StyledSelectTrigger {...field} {...fieldState}>
                        <SelectValue />
                      </StyledSelectTrigger>
                    </FormControl>
                    <SelectContent className="selectContent">
                      <SelectItem value="">No Min</SelectItem>
                      {priceRanges.map((v) => (
                        <SelectItem key={v.id} value={v.id}>
                          {v.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage className="font-normal" />
                </FormItem>
              );
            }}
          />
        </div>
        <div className="grow basis-0">
          {/* PRICE MAX */}
          <FormField
            control={control}
            name="priceMax"
            render={({ field, fieldState }) => {
              return (
                <FormItem className="mb-4">
                  <FormLabel className="text-inherit">Max Price</FormLabel>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <StyledSelectTrigger {...field} {...fieldState}>
                        <SelectValue />
                      </StyledSelectTrigger>
                    </FormControl>
                    <SelectContent className="selectContent">
                      <SelectItem value="">No Max</SelectItem>
                      {priceRanges.map((v) => (
                        <SelectItem key={v.id} value={v.id}>
                          {v.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage className="font-normal" />
                </FormItem>
              );
            }}
          />
        </div>
      </div>

      {/* BEDROOMS */}
      <FormField
        control={control}
        name="bedrooms"
        render={({ field }) => (
          <FormItem className="mb-4">
            <FormLabel>Bedrooms</FormLabel>
            <FormControl>
              <RadioGroup
                className="grid-cols-5"
                onValueChange={field.onChange}
                value={field.value}
              >
                <RadioButtonCards items={BEDROOMS} />
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />

      {/* BATHROOMS */}
      <FormField
        control={control}
        name="bathrooms"
        render={({ field }) => (
          <FormItem className="mb-4">
            <FormLabel>Bathrooms</FormLabel>
            <FormControl>
              <RadioGroup
                className="grid-cols-4"
                onValueChange={field.onChange}
                value={field.value}
              >
                <RadioButtonCards items={BATHROOMS} />
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />

      {/* COMMENTS */}
      <FormField
        control={control}
        name="comments"
        render={({ field }) => (
          <FormItem className="mb-4">
            <FormLabel>Comments</FormLabel>
            <FormControl>
              <Textarea
                className="text-base resize-none bg-neutral-200 border-neutral-200 border-2 focus-visible:border-black focus-visible:ring-0 shadow-none outline-none "
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}
