import React from "react";
import attribution from "@/assets/attribution@2x.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import { ActionFunctionArgs, redirect, useLoaderData } from "react-router";
import { LoaderData } from "./loader";
import { useForm } from "react-hook-form";
import { leadFormSchema } from "@/lib/validation";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Link, useFetcher } from "react-router-dom";
import { createLead } from "@/lib/api/create-lead";
import { ReloadIcon } from "@radix-ui/react-icons";
import GuestFormGroup from "@/components/guest/guest-form-group";
import { ContactInfo } from "@/components/common/contact-info";

export default function NewGuest() {
  const { campaign } = useLoaderData() as unknown as LoaderData;

  const fetcher = useFetcher();

  const errors = fetcher.data?.errors;

  const isLoading =
    fetcher.state === "submitting" || fetcher.state === "loading";

  const formInstance = useForm({
    defaultValues: {
      bedrooms: "",
      bathrooms: "",
      campaignRef: campaign.id,
      comments: "",
      emailAddress: "",
      name: "",
      phoneNumber: "",
      phoneType: "",
      priceMax: "",
      priceMin: "",
      sqftMax: "",
      sqftMin: "",
      textingAllowed: false,
    },
    resolver: yupResolver(leadFormSchema),
  });

  const { handleSubmit, register, setValue } = formInstance;

  const onSubmit = (formData: any) => {
    console.log(formData);

    const { phoneType, ...payload } = formData;

    const model = {
      ...payload,
    };

    ["priceMax", "priceMin", "sqftMax", "sqftMin"].forEach((name) => {
      const value = formData[name] as string | null | undefined;
      if (value) {
        const replace = value?.replace(/[^\d.]+/g, "");
        Object.assign(model, { [name]: replace });
      }
    });

    if (formData.phoneNumber && typeof formData.phoneNumber === "string") {
      Object.assign(model, {
        phoneNumber: formData.phoneNumber.replace(/[^\d+]/g, ""),
      });
    }

    if (phoneType) {
      Object.assign(model, { phoneType });
    }

    fetcher.submit(model, { method: "post" });
  };

  React.useEffect(() => {
    setValue("phoneType", "MOBILE");
    setValue("textingAllowed", true);
  }, [setValue]);

  return (
    <main className="m-4 sm:mx-auto max-w-6xl">
      <div className="sm:flex">
        {/* COLUMN 1 */}
        <div className="bg-transparent mx-2 sm:mx-4 sm:basis-2/4">
          <div className="text-xs mb-2">
            <Link
              to={{
                pathname: "..",
              }}
              relative="path"
            >
              Back
            </Link>
          </div>
          <div className="mb-4">
            <img src={campaign?.imageUrl} alt="" width="100%" />
          </div>
          <p className="text-sm">{campaign.copy}</p>
        </div>
        {/* COLUMN 2*/}
        <div className="bg-transparent mx-2 sm:mx-4 sm:grow">
          <div>
            <div className="font-bold text-2xl leading-8 mb-4">
              Guest Sign-In
            </div>
            {errors && (
              <div className="p-4 border border-red-100 bg-red-50 rounded-md text-sm my-2">
                Sorry, something went wrong. We&apos;ve notified notified
                technical support. In the meantime you can try again.
              </div>
            )}
            <Form {...formInstance}>
              <fetcher.Form method="post" onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" readOnly {...register("campaignRef")} />
                <GuestFormGroup />
                <div className="flex">
                  <Button className="flex-1" type="submit" disabled={isLoading}>
                    {isLoading && (
                      <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Submit
                  </Button>
                </div>
              </fetcher.Form>
            </Form>
          </div>
          <div className="my-5">
            <ContactInfo />
          </div>
          <div className="text-right p-3">
            <img
              width="177"
              alt="Powered by SpaceIt"
              src={attribution}
              className="inline"
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData();

  const data = [...formData.entries()].reduce((model, entry) => {
    return {
      ...model,
      [entry[0]]: entry[1],
    };
  }, {});

  const response = await createLead(data);

  if (!response.ok) {
    const res = await response?.json();
    Sentry.captureException(res);
    return { errors: { ...res }, status: response.status };
  }
  return redirect(`/guest/${params.id}`);
}
