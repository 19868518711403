import { Button } from "@/components/ui/button";
import attribution from "@/assets/attribution@2x.png";
import logo from "@/assets/douglaselliman.png";
import * as Sentry from "@sentry/react";
import {
  ActionFunctionArgs,
  LoaderFunction,
  json,
  useLoaderData,
} from "react-router";
import { cancelAttendance } from "@/lib/api/cancel-open-house-attendance";
import { LoaderParams } from "@/lib/types/loader";
import invariant from "tiny-invariant";
import { useFetcher } from "react-router-dom";
import { getLeadOpenHouse } from "@/lib/api/get-lead-open-house";
import { format } from "date-fns";

export type LoaderData = {
  data: NonNullable<Awaited<ReturnType<typeof getLeadOpenHouse>>>;
  leadRef: string;
};

export const loader: LoaderFunction = async ({ params }: LoaderParams) => {
  invariant(params?.id, "id not found");

  const data = await getLeadOpenHouse(params.id);

  if (!data) {
    throw new Response("Not Found", { status: 404 });
  }
  return { data, leadRef: params.id };
};

export default function CancelOpenHouseAttendance() {
  const { data: leadOpenHouse, leadRef } =
    useLoaderData() as unknown as LoaderData;

  const { propertyName, openHouse } = leadOpenHouse;

  const makeDateTimeLabel = () => {
    if (!openHouse) return undefined;

    const day = format(new Date(openHouse.start), "EEEE, MMM. dd, yyyy");

    const start = format(new Date(openHouse.start), "h:mmaaa");

    const end = format(new Date(openHouse.end), "h:mmaaa");

    return `${day} (${start} - ${end})`;
  };

  const fetcher = useFetcher();

  const errors = fetcher.data?.errors;

  const onSubmit = (e: any) => {
    e.preventDefault();
    fetcher.submit(
      { leadRef },
      { method: "post", encType: "application/json" }
    );
  };

  const data = fetcher.data;

  return (
    <main className="bg-sky-50">
      <div className="container max-w-2xl py-4">
        <div className="mb-4">
          <img width={350} alt="Douglas Elliman" src={logo} />
        </div>
        <div>
          <h1 className="text-2xl font-bold mb-4">Open House Attendance</h1>
          {openHouse && (
            <>
              <div className="text-base">{propertyName}</div>
              <div className="text-base mb-4">{makeDateTimeLabel()}</div>
            </>
          )}
        </div>

        {errors && (
          <div className="p-4 border border-red-100 bg-red-50 rounded-md text-sm my-2">
            Sorry, something went wrong. We&apos;ve notified notified technical
            support.
          </div>
        )}

        {data?.status === "success" && (
          <div className="mb-2">Attendance cancelled.</div>
        )}

        {openHouse ? (
          <>
            <div className="text-base mb-4">
              Click the button below to remove yourself from the open house
              attendance.
            </div>
            <div className="text-left mb-4">
              <fetcher.Form method="post" onSubmit={onSubmit}>
                <Button
                  disabled={fetcher.state === "submitting"}
                  className="text-base h-auto"
                  type="submit"
                >
                  Cancel open house attendance
                </Button>
              </fetcher.Form>
            </div>
          </>
        ) : (
          <div className="text-base mb-4">
            You are no longer scheduled for any open houses.
          </div>
        )}
      </div>
      <div className="text-right p-3">
        <img
          width="177"
          alt="Powered by SpaceIt"
          src={attribution}
          className="inline"
        />
      </div>
    </main>
  );
}

export async function action({ request }: ActionFunctionArgs) {
  const data = await request.json();

  const response = await cancelAttendance(data.leadRef);
  if (!response.ok) {
    const res = await response?.json();
    console.log(res);
    Sentry.captureException(res);
    return { errors: { ...res }, status: response.status };
  }

  return json({ status: "success", d: data });
}
