import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// export function json(obj: any) {
//   return new Response(JSON.stringify(obj), {
//     headers: {
//       "Content-Type": "application/json; charset=utf-8",
//     },
//   });
// }
