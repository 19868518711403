export default function ErrorLayout({ children }: any) {
  return (
    <div
      id="error-page"
      className="bg-neutral-50 flex flex-col items-center justify-center min-h-screen text-center"
    >
      <h1 className="text-xl font-semibold mb-2">
        Sorry! An unexpected error has occurred.
      </h1>
      <p className="text-sm mb-4">We&apos;ve notified technical support.</p>
      <div>{children}</div>
    </div>
  );
}
