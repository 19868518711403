import {
  Select,
  SelectContent,
  SelectItem,
  // SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import InputPatternFormat from "../components/common/input-pattern-format";
import StyledInput from "../components/common/styled-input";
import StyledSelectTrigger from "../components/common/styled-select-trigger";
import { useFormContext } from "react-hook-form";
import RequiredMarker from "../components/common/required-marker";

export default function AgentContactInfo() {
  const {
    control,
    // setValue,
    watch,
    trigger,
    formState: { isSubmitted },
  } = useFormContext<any>();

  const [leadType$] = watch(["leadType"]);

  const validateEmail = () => {
    if (isSubmitted) {
      trigger("brokerEmailAddress");
      trigger("brokerPhoneType");
    }
  };

  const validatePhoneNumber = () => {
    if (isSubmitted) {
      trigger("brokerPhoneNumber");
    }
  };
  return (
    <div>
      {/* NAME */}
      <FormField
        control={control}
        name="brokerName"
        render={({ field, fieldState }) => (
          <FormItem className="mb-4">
            <FormLabel className="text-inherit">
              {leadType$ === "TENANT" ? (
                <>Broker&apos;s Name</>
              ) : (
                <RequiredMarker>Name</RequiredMarker>
              )}
            </FormLabel>
            <FormControl>
              <StyledInput {...field} {...fieldState} />
            </FormControl>
            <FormMessage className="font-normal" />
          </FormItem>
        )}
      />

      {/* FIRM */}
      <FormField
        control={control}
        name="brokerCompany"
        render={({ field, fieldState }) => (
          <FormItem className="mb-4">
            <FormLabel className="text-inherit">
              {leadType$ === "TENANT" ? (
                <>Brokerage Firm</>
              ) : (
                <RequiredMarker>Brokerage Firm</RequiredMarker>
              )}
            </FormLabel>
            <FormControl>
              <StyledInput {...field} {...fieldState} />
            </FormControl>
            <FormMessage className="font-normal" />
          </FormItem>
        )}
      />
      {leadType$ === "REPRESENTATIVE" && (
        <div className="text-xs text-yellow-600 pb-2">
          Only one contact method required, an email or phone number.
        </div>
      )}
      {/* EMAIL */}
      <FormField
        control={control}
        name="brokerEmailAddress"
        render={({ field, fieldState }) => (
          <FormItem className="mb-4">
            <FormLabel className="text-inherit">
              {leadType$ === "TENANT" ? (
                <>Broker&apos;s Email</>
              ) : (
                <RequiredMarker className="text-yellow-600">
                  Email
                </RequiredMarker>
              )}
            </FormLabel>
            <FormControl>
              <StyledInput
                {...field}
                {...fieldState}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e.target.value);
                  validatePhoneNumber();
                }}
              />
            </FormControl>
            <FormMessage className="font-normal" />
          </FormItem>
        )}
      />

      {/* PHONE */}
      <div className="flex gap-4">
        <div className="grow basis-5">
          <FormField
            control={control}
            name="brokerPhoneNumber"
            render={({ field, fieldState }) => (
              <FormItem className="mb-4">
                <FormLabel className="text-inherit">
                  {leadType$ === "TENANT" ? (
                    <>Broker&apos;s Phone Number</>
                  ) : (
                    <RequiredMarker className="text-yellow-600">
                      Phone Number
                    </RequiredMarker>
                  )}
                </FormLabel>
                <FormControl>
                  <InputPatternFormat
                    format="+1 (###) ###-####"
                    {...field}
                    {...fieldState}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      field.onChange(e.target.value);
                      validateEmail();
                    }}
                  />
                  {/* <Input
              {...field}
            /> */}
                </FormControl>
                <FormMessage className="font-normal" />
              </FormItem>
            )}
          />
        </div>
        <div className="grow basis-0">
          <FormField
            control={control}
            name="brokerPhoneType"
            render={({ field, fieldState }) => {
              return (
                <FormItem className="mb-4">
                  <FormLabel className="text-inherit">
                    {leadType$ === "TENANT" ? (
                      <>Broker&apos;s Phone Type</>
                    ) : (
                      <RequiredMarker className="text-yellow-600">
                        Phone Type
                      </RequiredMarker>
                    )}
                  </FormLabel>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <StyledSelectTrigger {...field} {...fieldState}>
                        <SelectValue />
                      </StyledSelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="">Select</SelectItem>
                      <SelectItem value="MOBILE">Mobile</SelectItem>
                      <SelectItem value="HOME">Home</SelectItem>
                      <SelectItem value="OFFICE">Office</SelectItem>
                      <SelectItem value="OTHER">Other</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage className="font-normal" />
                </FormItem>
              );
            }}
          />
        </div>
      </div>
      {/* TEXT OPT N/A for brokerPhone */}
      {/* {brokerPhoneType$ === "MOBILE" && (
        <FormField
          control={control}
          name="textingAllowed"
          render={({ field }) => (
            <FormItem className="mb-4 flex flex-row items-center space-x-2 space-y-0 ">
              <FormControl>
                <Checkbox
                  className="w-5 h-5 border-2 shadow-none"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel className="text-inherit">
                OK to receive text message from agent
              </FormLabel>
              <FormMessage className="font-normal" />
            </FormItem>
          )}
        />
      )} */}
    </div>
  );
}
