import React from "react";
import type { NumericFormatProps } from "react-number-format";
import { NumericFormat } from "react-number-format";
import StyledInput from "./styled-input";

const InputNumericFormat = React.forwardRef(
  (props: NumericFormatProps<any>, ref) => {
    // const shouldShowError = Boolean(errors[name]);
    return (
      <NumericFormat getInputRef={ref} customInput={StyledInput} {...props} />
    );
  }
);

export default InputNumericFormat;
