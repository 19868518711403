import InputPatternFormat from "@/components/common/input-pattern-format";
import RequiredMarker from "@/components/common/required-marker";
import StyledInput from "@/components/common/styled-input";
import StyledSelectTrigger from "@/components/common/styled-select-trigger";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import { useFormContext } from "react-hook-form";

export function LeadContactInfo() {
  const {
    control,
    watch,
    trigger,
    formState: { isSubmitted },
  } = useFormContext<any>();

  const [leadType$, phoneType$] = watch(["leadType", "phoneType"]);

  const validateEmail = () => {
    if (isSubmitted) {
      trigger("emailAddress");
    }
  };

  const validatePhoneNumber = () => {
    if (isSubmitted) {
      trigger("phoneNumber");
    }
  };

  return (
    <>
      <FormField
        control={control}
        name="name"
        render={({ field, fieldState }) => (
          <FormItem className="mb-4">
            <FormLabel className="text-inherit">
              <RequiredMarker>Name</RequiredMarker>
            </FormLabel>
            <FormControl>
              <StyledInput {...field} {...fieldState} />
            </FormControl>
            <FormMessage className="font-normal" />
          </FormItem>
        )}
      />

      {/* COMPANY */}
      {leadType$ === "REPRESENTATIVE" && (
        <FormField
          control={control}
          name="company"
          render={({ field, fieldState }) => (
            <FormItem className="mb-4">
              <FormLabel className="text-inherit">
                <RequiredMarker>Brokerage Firm</RequiredMarker>
              </FormLabel>
              <FormControl>
                <StyledInput {...field} {...fieldState} />
              </FormControl>
              <FormMessage className="font-normal" />
            </FormItem>
          )}
        />
      )}

      <div className="text-xs text-yellow-600 pb-2">
        Only one contact method required, an email or phone number.
      </div>

      <FormField
        control={control}
        name="emailAddress"
        render={({ field, fieldState }) => (
          <FormItem className="mb-4">
            <FormLabel className="text-inherit">
              <RequiredMarker className="text-yellow-600">Email</RequiredMarker>
            </FormLabel>
            <FormControl>
              <StyledInput
                {...field}
                {...fieldState}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e.target.value);
                  validatePhoneNumber();
                }}
              />
            </FormControl>
            <FormMessage className="font-normal" />
          </FormItem>
        )}
      />

      <div className="flex gap-4">
        <div className="grow basis-0">
          <FormField
            control={control}
            name="phoneNumber"
            render={({ field, fieldState }) => (
              <FormItem className="mb-4">
                <FormLabel className="text-inherit">
                  <RequiredMarker className="text-yellow-600">
                    Phone Number
                  </RequiredMarker>
                </FormLabel>
                <FormControl>
                  <InputPatternFormat
                    format="+1 (###) ###-####"
                    {...field}
                    {...fieldState}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      field.onChange(e.target.value);
                      validateEmail();
                    }}
                  />
                </FormControl>
                <FormMessage className="font-normal" />
              </FormItem>
            )}
          />
        </div>
        <div className="grow basis-0">
          <FormField
            control={control}
            name="phoneType"
            render={({ field, fieldState }) => {
              return (
                <FormItem className="mb-4">
                  <FormLabel className="text-inherit">
                    <RequiredMarker className="text-yellow-600">
                      Phone Type
                    </RequiredMarker>
                  </FormLabel>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <StyledSelectTrigger {...field} {...fieldState}>
                        <SelectValue placeholder="Select" />
                      </StyledSelectTrigger>
                      {/* <SelectTrigger className="bg-neutral-200 shadow-none focus:ring-black focus:ring-2">
                    <SelectValue placeholder="Select" />
                  </SelectTrigger> */}
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="MOBILE">Mobile</SelectItem>
                      <SelectItem value="HOME">Home</SelectItem>
                      <SelectItem value="OFFICE">Office</SelectItem>
                      <SelectItem value="OTHER">Other</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage className="font-normal" />
                </FormItem>
              );
            }}
          />
        </div>
      </div>

      {/* TEXT OPT */}
      {phoneType$ === "MOBILE" && (
        <FormField
          control={control}
          name="textingAllowed"
          render={({ field }) => (
            <FormItem className="mb-4 flex flex-row items-center space-x-2 space-y-0 ">
              <FormControl>
                <Checkbox
                  className="w-5 h-5 border-2 shadow-none"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel className="text-inherit">
                OK to receive text message from agent
              </FormLabel>
              <FormMessage className="font-normal" />
            </FormItem>
          )}
        />
      )}
    </>
  );
}
