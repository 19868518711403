import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import StyledInput from "../components/common/styled-input";
import { useFormContext } from "react-hook-form";
import RequiredMarker from "../components/common/required-marker";

export default function TenantNameField() {
  const { control } = useFormContext<any>();

  return (
    <FormField
      control={control}
      name="tenantName"
      render={({ field, fieldState }) => (
        <FormItem className="mb-4">
          <FormLabel className="text-inherit">
            <RequiredMarker>Client&apos;s Name</RequiredMarker>
          </FormLabel>
          <FormControl>
            <StyledInput {...field} {...fieldState} />
          </FormControl>
          <FormMessage className="font-normal" />
        </FormItem>
      )}
    />
  );
}
