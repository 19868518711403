import * as Sentry from "@sentry/browser";
import { isRouteErrorResponse, useRouteError } from "react-router";
import ErrorLayout from "./error-layout";
import { ErrorContactInfo } from "./error-contact-info";

export function ErrorBoundary() {
  const error = useRouteError();

  Sentry.captureException(error);

  if (error instanceof Error) {
    return (
      <ErrorLayout>
        <div className="italic">{error.message}</div>
        <ErrorContactInfo />
      </ErrorLayout>
    );
  }

  if (!isRouteErrorResponse(error)) {
    return (
      <ErrorLayout>
        <div className="italic">Unknown Error</div>
        <ErrorContactInfo />
      </ErrorLayout>
    );
  }

  if (error.status === 400) {
    return (
      <ErrorLayout>
        <ErrorContactInfo />
      </ErrorLayout>
    );
  }

  if (error.status === 404) {
    return (
      <ErrorLayout>
        <div className="italic">Page not found</div>
        <ErrorContactInfo />
      </ErrorLayout>
    );
  }

  return (
    <div className="italic">
      {error.statusText} <ErrorContactInfo />
    </div>
  );
}
