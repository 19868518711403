export default function SplashPage() {
  return (
    <div className="bg-white flex flex-col items-center m-10">
      <h1 className="text-center text-3xl font-extrabold">
        <span className="text-black-500 block drop-shadow-md">
          SpaceIt<sup>&trade;</sup>
        </span>
      </h1>
    </div>
  );
}
