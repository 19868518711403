import { JHipsterException } from "../types/loader";
import { Property } from "../types/property";

export type OpenHouseCampaign = {
  externalUserId: string;
  property: Property;
};

const baseUrl = import.meta.env.VITE_APP_API as string;

export async function getOpenHouseCampaign(
  campaignRef: string | undefined
  // _request: Request
): Promise<OpenHouseCampaign | undefined> {
  const response = await fetch(
    `${baseUrl}/api/open-house-campaign/${campaignRef}`
  );

  const body: OpenHouseCampaign = await response.json();

  if (response.ok) {
    if (body.property.id) {
      return body;
    } else {
      return Promise.reject(
        new Error(`No property data for campaign ${campaignRef}`)
      );
    }
  } else {
    const e = body as unknown as JHipsterException;
    const errors = [e?.message, e?.detail];
    const error = new Error(errors.filter((v) => v).join("\n") ?? e);
    return Promise.reject(error);
  }
}
