import React from "react";
import { SelectTrigger } from "@/components/ui/select";
import { cn } from "@/lib/utils";

const StyledSelectTrigger = React.forwardRef((props: any, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, error, invalid, isTouched, isDirty, ...rest } = props;

  return (
    <SelectTrigger
      ref={ref}
      {...rest}
      className={cn(
        error?.message
          ? "bg-red-100 border-red-300 focus-visible:bg-red-50"
          : "bg-neutral-200 border-neutral-200",
        "shadow-none focus:border-black focus:border-2 focus:ring-0"
      )}
    >
      {children}
    </SelectTrigger>
  );
});

export default StyledSelectTrigger;
