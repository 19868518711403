import { cn } from "@/lib/utils";
import React from "react";

type PropsT = {
  children?: React.ReactNode;
  className?: string;
};

export default function RequiredMarker({ children, className }: PropsT) {
  return (
    <>
      <span className={cn("text-red-400 pr-1", className)}>*</span>
      {children}
    </>
  );
}
