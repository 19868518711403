const baseUrl = import.meta.env.VITE_APP_API as string;

export const createLead = async (formData: any) => {
  const url = `${baseUrl}/api/communication/form-submit`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(formData),
  });
  return response;
};
