import React from "react";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";

const StyledInput = React.forwardRef((props: any, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error, invalid, isTouched, isDirty, ...rest } = props;

  return (
    <Input
      ref={ref}
      {...rest}
      className={cn(
        error?.message
          ? "bg-red-100 border-red-300 focus-visible:bg-red-50"
          : "bg-neutral-200 border-neutral-200",
        "border-2 focus-visible:border-black focus-visible:ring-0 shadow-none outline-none py-2 text-base"
      )}
    />
  );
});

export default StyledInput;
