import { useFormContext } from "react-hook-form";
import { useNumericFormat } from "react-number-format";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import StyledSelectTrigger from "@/components/common/styled-select-trigger";
import RadioButtonCards from "@/components/common/radio-button-cards";
import InputNumericFormat from "@/components/common/input-numeric-format";
import { RadioGroup } from "@/components/ui/radio-group";
import { BATHROOMS, BEDROOMS, PRICES } from "@/lib/constants";
import React from "react";

export default function SearchCriteria() {
  const { control, setValue, watch } = useFormContext<any>();

  const [priceMax$, priceMin$] = watch(["priceMax", "priceMin"]);

  const { format } = useNumericFormat({
    thousandSeparator: true,
    prefix: "$",
  });

  const priceRanges = React.useMemo(
    () =>
      PRICES.map((n) => ({
        id: `${n}`,
        label: format!(`${n}`),
      })),
    [format]
  );

  React.useEffect(() => {
    if (priceMin$ && priceMax$) {
      const [min, max] = [parseInt(priceMin$, 10), parseInt(priceMax$, 10)];
      if (min > max) {
        setValue("priceMin", max);
        setValue("priceMax", min);
      }
    }
  }, [priceMin$, priceMax$, setValue]);
  return (
    <>
      {/* SQFT */}
      <div className="flex gap-4">
        <div className="grow basis-0">
          {/* SQFT MIN */}
          <FormField
            control={control}
            name="sqftMin"
            render={({ field, fieldState }) => (
              <FormItem className="mb-4">
                <FormLabel className="text-inherit">Min Square Feet</FormLabel>
                <FormControl>
                  <InputNumericFormat
                    thousandSeparator
                    inputMode="numeric"
                    placeholder="No Min"
                    decimalScale={0}
                    {...field}
                    {...fieldState}
                  />
                </FormControl>
                <FormMessage className="font-normal" />
              </FormItem>
            )}
          />
        </div>
        <div className="grow basis-0">
          {/* SQFT MAX */}
          <FormField
            control={control}
            name="sqftMax"
            render={({ field, fieldState }) => (
              <FormItem className="mb-4">
                <FormLabel className="text-inherit">Max Square Feet</FormLabel>
                <FormControl>
                  <InputNumericFormat
                    thousandSeparator
                    inputMode="numeric"
                    placeholder="No Max"
                    decimalScale={0}
                    {...field}
                    {...fieldState}
                  />
                </FormControl>
                <FormMessage className="font-normal" />
              </FormItem>
            )}
          />
        </div>
      </div>

      {/* PRICE */}
      <div className="flex gap-4">
        <div className="grow basis-0">
          {/* PRICE MIN*/}
          <FormField
            control={control}
            name="priceMin"
            render={({ field, fieldState }) => {
              return (
                <FormItem className="mb-4">
                  <FormLabel className="text-inherit">Min Price</FormLabel>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <StyledSelectTrigger {...field} {...fieldState}>
                        <SelectValue />
                      </StyledSelectTrigger>
                    </FormControl>
                    <SelectContent className="selectContent">
                      <SelectItem value="">No Min</SelectItem>
                      {priceRanges.map((v) => (
                        <SelectItem key={v.id} value={v.id}>
                          {v.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage className="font-normal" />
                </FormItem>
              );
            }}
          />
        </div>
        <div className="grow basis-0">
          {/* PRICE MAX */}
          <FormField
            control={control}
            name="priceMax"
            render={({ field, fieldState }) => {
              return (
                <FormItem className="mb-4">
                  <FormLabel className="text-inherit">Max Price</FormLabel>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <StyledSelectTrigger {...field} {...fieldState}>
                        <SelectValue />
                      </StyledSelectTrigger>
                    </FormControl>
                    <SelectContent className="selectContent">
                      <SelectItem value="">No Max</SelectItem>
                      {priceRanges.map((v) => (
                        <SelectItem key={v.id} value={v.id}>
                          {v.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage className="font-normal" />
                </FormItem>
              );
            }}
          />
        </div>
      </div>

      {/* BEDROOMS */}
      <FormField
        control={control}
        name="bedrooms"
        render={({ field }) => (
          <FormItem className="mb-4">
            <FormLabel>Bedrooms</FormLabel>
            <FormControl>
              <RadioGroup
                className="grid-cols-5"
                onValueChange={field.onChange}
                value={field.value}
              >
                <RadioButtonCards items={BEDROOMS} />
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />

      {/* BATHROOMS */}
      <FormField
        control={control}
        name="bathrooms"
        render={({ field }) => (
          <FormItem className="mb-4">
            <FormLabel>Bathrooms</FormLabel>
            <FormControl>
              <RadioGroup
                className="grid-cols-4"
                onValueChange={field.onChange}
                value={field.value}
              >
                <RadioButtonCards items={BATHROOMS} />
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />
    </>
  );
}
