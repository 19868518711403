import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { useFormContext } from "react-hook-form";

export default function CommentField() {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name="comments"
      render={({ field }) => (
        <FormItem className="mb-4">
          <FormLabel>Comments</FormLabel>
          <FormControl>
            <Textarea
              className="text-base resize-none bg-neutral-200 border-neutral-200 border-2 focus-visible:border-black focus-visible:ring-0 shadow-none outline-none "
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
