import { OpenHouse } from "@/lib/api/get-short-property";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { format } from "date-fns";
import { useFormContext } from "react-hook-form";

type Props = {
  openHouses: OpenHouse[] | null;
};

export default function OpenHouseAppts({ openHouses }: Props) {
  const { control } = useFormContext<any>();

  const options = openHouses?.map((item) => {
    const day = format(new Date(item.start), "EEEE, MMMM dd");
    const start = format(new Date(item.start), "h:mmaaa");
    const end = format(new Date(item.end), "h:mmaaa");
    return {
      ...item,
      day,
      start,
      end,
    };
  });

  return (
    <FormField
      control={control}
      name="openHouseSelectionCampaignRef"
      render={({ field }) => {
        return (
          <FormItem className="mb-4 space-y-3">
            <FormLabel>
              <div>Open House</div>
              <div className="text-sm text-zinc-500 font-normal">
                Pick an open house to attend and we&apos;ll send a calendar
                invite.
              </div>
            </FormLabel>
            <FormControl>
              <RadioGroup
                defaultValue={field.value}
                onValueChange={field.onChange}
              >
                {options?.map((appointment, i) => {
                  return (
                    <div
                      data-id="OPT"
                      key={appointment.formCampaignRef}
                      className="flex items-start space-x-2 mb-2"
                    >
                      <RadioGroupItem
                        value={appointment.formCampaignRef}
                        id={`r${i}`}
                      />
                      <Label htmlFor={`r${i}`} className="cursor-pointer">
                        <div className="mb-2">{appointment.day}</div>
                        <div className="text-zinc-600">
                          {appointment.start} - {appointment.end}
                        </div>
                      </Label>
                    </div>
                  );
                })}
                <div className="flex items-center space-x-2 mb-2">
                  <RadioGroupItem value="none" id="def" />
                  <Label htmlFor="def" className="cursor-pointer">
                    None
                  </Label>
                </div>
              </RadioGroup>
            </FormControl>
          </FormItem>
        );
      }}
    />
  );
}
