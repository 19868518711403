import { useLoaderData } from "react-router";
import attribution from "@/assets/attribution@2x.png";
import { Button } from "@/components/ui/button";
import logo from "@/assets/douglaselliman.png";
import { LoaderData } from "./loader";
import { useSearchParams } from "react-router-dom";

export default function Confirmation() {
  const { data } = useLoaderData() as unknown as LoaderData;

  const [searchParams] = useSearchParams();

  const view = searchParams.get("v");

  const agentLink = `https://elliman.com/${data.externalUserId}`;

  const propertyLink = `https://elliman.com/${data.property?.externalListingId}`;

  return (
    <main className="bg-sky-50">
      <div className="container max-w-2xl py-4">
        <div className="mb-4">
          <img width={350} alt="Douglas Elliman" src={logo} />
        </div>
        <div>
          <h1 className="text-2xl font-bold">Confirmation</h1>
          <p className="text-base mb-4">
            Thank you for inquiring about {data.property.name}.
          </p>
        </div>
        <div className="text-center mb-4">
          {data.property?.externalAvailableOnWeb && (
            <Button className="text-base h-auto" asChild>
              <a href={propertyLink}>See more details on this property</a>
            </Button>
          )}
        </div>
        {view === "l" && data.externalUserId && (
          <div className="text-center mb-4">
            <Button className="text-base h-auto" asChild>
              <a href={agentLink}> See other properties from this agent</a>
            </Button>
          </div>
        )}
      </div>
      <div className="text-right p-3">
        <img
          width="177"
          alt="Powered by SpaceIt"
          src={attribution}
          className="inline"
        />
      </div>
    </main>
  );
}
