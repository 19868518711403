import { JHipsterException } from "../types/loader";
import { Property } from "../types/property";

type Campaign = {
  category: string;
  copy: string;
  id: string;
  imageUrl: string;
  listingRef: string;
  name: string;
  scope: "SPECIFIC_MEMBERS" | "WHOLE_TEAM";
  teamMembers: string[];
  teamRef: string;
  type: "CHANNEL" | "FORM";
};

type ShortProperty = {
  campaign: Campaign;
  team: {
    openHouses: OpenHouse[];
  };
  externalUserId: string;
  property: Property;
};

export type OpenHouse = {
  assignedAgent: string;
  cancelled: boolean;
  end: string;
  formCampaignRef: string;
  start: string;
};

const baseUrl = import.meta.env.VITE_APP_API as string;

export async function getShortProperty(
  shortPropertyRef: string | undefined
  // _request: Request
): Promise<ShortProperty | undefined> {
  const response = await fetch(
    `${baseUrl}/api/property-form/${shortPropertyRef}`
  );

  const body: ShortProperty = await response.json();

  if (response.ok) {
    if (body.campaign.id) {
      return body;
    } else {
      return Promise.reject(
        new Error(`No property with short id ${shortPropertyRef}`)
      );
    }
  } else {
    const e = body as unknown as JHipsterException;
    const errors = [e?.message, e?.detail];
    const error = new Error(errors.filter((v) => v).join("\n") ?? e);
    return Promise.reject(error);
  }
}
