import { getCampaign } from "@/lib/api/get-campaign";
import { LoaderParams } from "@/lib/types/loader";
import invariant from "tiny-invariant";

export type LoaderData = {
  campaign: NonNullable<Awaited<ReturnType<typeof getCampaign>>>;
};

export const loader = async ({ params }: LoaderParams) => {
  invariant(params?.id, "id not found");

  const data = await getCampaign(params.id);
  const headers = {
    "Cache-Control":
      "public, max-age=300, s-maxage=43200, stale-while-revalidate=60",
  };
  return <LoaderData & { headers: any }>{ campaign: data, headers };
};
