export function ErrorContactInfo() {
  return (
    <div className="text-sm my-5 max-w-xl">
      <div>SpaceIt encountered a problem processing your request.</div>
      <div>For help, please contact SpaceIt support at</div>
      <div>
        <a
          className="text-cyan-700 hover:underline"
          href="mailto:customer-service@spaceitproperty.elliman.com"
        >
          customer-service@spaceitproperty.elliman.com
        </a>
      </div>
    </div>
  );
}
