import { getCampaign } from "@/lib/api/get-campaign";
import { LoaderParams } from "@/lib/types/loader";
import { getOpenHouseCampaign } from "@/lib/api/get-open-house-campaign";
import invariant from "tiny-invariant";

export type LoaderData = {
  campaign: NonNullable<Awaited<ReturnType<typeof getCampaign>>>;
  openHouse: NonNullable<Awaited<ReturnType<typeof getOpenHouseCampaign>>>;
};

export const loader = async ({ params }: LoaderParams) => {
  invariant(params?.id, "id not found");

  const data = await getCampaign(params.id);
  const openHouse = await getOpenHouseCampaign(params.id);
  return <LoaderData>{ campaign: data, openHouse };
};
