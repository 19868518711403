import React from "react";
import type { PatternFormatProps } from "react-number-format";
import { PatternFormat } from "react-number-format";
import StyledInput from "./styled-input";

const InputPatternFormat = React.forwardRef(
  (props: PatternFormatProps<any>, ref) => {
    return (
      <PatternFormat getInputRef={ref} customInput={StyledInput} {...props} />
    );
  }
);

export default InputPatternFormat;
