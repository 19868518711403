import { FormControl, FormItem, FormLabel } from "@/components/ui/form";
import { RadioGroupItem } from "@/components/ui/radio-group";

type Option = {
  label: string;
  value: string;
};

type Props = {
  items: Option[];
};

export default function RadioButtonCards({ items }: Props) {
  return (
    <>
      {items.map((item) => (
        <FormItem key={item.value} className="items-center space-y-0">
          <FormControl className="hidden">
            <RadioGroupItem value={item.value} />
          </FormControl>
          <FormLabel className="cursor-pointer items-center bg-neutral-200 border-2 border-neutral-200 rounded-md flex flex-col p-3 text-base card ">
            {item.label}
          </FormLabel>
        </FormItem>
      ))}
    </>
  );
}
