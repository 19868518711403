import { useFieldArray, useFormContext } from "react-hook-form";
import { Button } from "@/components/ui/button";
import AddTenant from "../pages/inquiry/add-tenant";
import { TrashIcon } from "@radix-ui/react-icons";

export default function AdditionalTenants() {
  const { control, watch } = useFormContext();

  const tenants$ = watch("additionalTenants");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "additionalTenants",
  });

  const removeTenant = (i: number) => {
    remove(i);
  };

  return (
    <div className="space-y-3 mb-6">
      <div className="text-sm font-medium">
        Are you representing additional clients?
      </div>
      {fields.map((field: any, index) => {
        return (
          <div key={field.id} className="">
            <div className="flex items-center gap-4 border-b border-zinc-300">
              <div className="flex-1 text-sm">{tenants$[index].tenantName}</div>
              <div className="grow-0 shrink-0">
                <Button
                  size="sm"
                  onClick={() => removeTenant(index)}
                  variant="link"
                >
                  <TrashIcon />
                  Remove
                </Button>
              </div>
            </div>
          </div>
        );
      })}
      <AddTenant append={append} />
    </div>
  );
}
