import attribution from "@/assets/attribution@2x.png";
import logo from "@/assets/douglaselliman.png";
import { Form } from "@/components/ui/form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import { ActionFunctionArgs, redirect, useLoaderData } from "react-router";
import { LoaderData } from "../inquiry/loader";
import { useFetcher } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  baseSchema,
  brokerRepSchema,
  searchCriteriaSchema,
  tenantSchema,
} from "@/lib/validation";
import { Button } from "@/components/ui/button";
import FormFields from "./form-fields";
import { ReloadIcon } from "@radix-ui/react-icons";
import { useEffect } from "react";
import { createLead } from "@/lib/api/create-lead";
import { ContactInfo } from "@/components/common/contact-info";

export default function Property() {
  const { data } = useLoaderData() as unknown as LoaderData;

  const fetcher = useFetcher();

  const errors = fetcher.data?.errors;

  const isLoading =
    fetcher.state === "submitting" || fetcher.state === "loading";

  const formInstance = useForm({
    defaultValues: {
      bedrooms: "",
      bathrooms: "",
      campaignRef: data.campaign.id,
      comments: "",
      emailAddress: "",
      representedByBroker: "",
      leadType: "",
      name: "",
      phoneNumber: "",
      phoneType: "",
      priceMax: "",
      priceMin: "",
      propertyType: "",
      sqftMax: "",
      sqftMin: "",
      textingAllowed: false,
      brokerName: "",
      brokerCompany: "",
      brokerPhoneNumber: "",
      brokerPhoneType: "",
      brokerEmailAddress: "",
      tenantName: "",
      additionalTenants: [],
      company: "",
      openHouseSelectionCampaignRef: "",
    },
    resolver: yupResolver(
      baseSchema
        .concat(tenantSchema)
        .concat(brokerRepSchema)
        .concat(searchCriteriaSchema)
    ),
  });

  const { watch, handleSubmit, register, setValue, formState } = formInstance;

  const onSubmit = (formData: any) => {
    const { phoneType, brokerPhoneType, ...payload } = formData;

    const model = {
      ...payload,
      propertyType: data.property?.propertyType,
    };

    ["priceMax", "priceMin", "sqftMax", "sqftMin"].forEach((name) => {
      const value = formData[name] as string | null | undefined;
      if (value) {
        const replace = value?.replace(/[^\d.]+/g, "");
        Object.assign(model, { [name]: replace });
      }
    });

    if (formData.phoneNumber && typeof formData.phoneNumber === "string") {
      Object.assign(model, {
        phoneNumber: formData.phoneNumber.replace(/[^\d+]/g, ""),
      });
    }

    if (
      formData.brokerPhoneNumber &&
      typeof formData.brokerPhoneNumber === "string"
    ) {
      Object.assign(model, {
        brokerPhoneNumber: formData.brokerPhoneNumber.replace(/[^\d+]/g, ""),
      });
    }

    if (phoneType) {
      Object.assign(model, { phoneType });
    }

    if (brokerPhoneType) {
      Object.assign(model, { brokerPhoneType });
    }

    fetcher.submit(model, { method: "post", encType: "application/json" });
  };

  const openHouses = data.team.openHouses;

  const deb = localStorage.getItem("debugKey");

  useEffect(() => {
    setValue("phoneType", "MOBILE");
    setValue("textingAllowed", true);
  }, [setValue]);

  const sub = watch();

  return (
    <main className="container max-w-2xl">
      <div>
        <img width={350} alt="Douglas Elliman" src={logo} />
      </div>
      <div className="text-sm"> {data.property?.name}</div>
      <div className="font-bold leading-8">Sign-In</div>
      {errors && (
        <div className="p-4 border border-red-100 bg-red-50 rounded-md text-sm my-2">
          Sorry, something went wrong. We&apos;ve notified notified technical
          support. In the meantime you can try again.
        </div>
      )}
      <Form {...formInstance}>
        <fetcher.Form method="post" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" readOnly {...register("campaignRef")} />
          <FormFields openHouseSchedule={openHouses} />
          <div className="flex">
            <Button className="flex-1" type="submit" disabled={isLoading}>
              {isLoading && (
                <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
              )}
              Submit
            </Button>
          </div>
          <div className="text-right p-3">
            <img
              width="177"
              alt="Powered by SpaceIt"
              src={attribution}
              className="inline"
            />
          </div>
        </fetcher.Form>
      </Form>
      {deb === "0u812" && (
        <>
          <pre className="w-64 absolute top-0 left-0 bottom-0 text-xs whitespace-pre-line">
            {JSON.stringify(sub, null, 2)}
          </pre>

          <pre className="text-xs">{JSON.stringify(formState.errors, null, 2)}</pre>
        </>
      )}
      <ContactInfo />
    </main>
  );
}

export async function action({ request, params }: ActionFunctionArgs) {
  const data = await request.json();

  const showLinks = data.representedByBroker === "false" ? "l" : "";

  const response = await createLead(data);

  if (!response.ok) {
    const res = await response?.json();
    Sentry.captureException(res);
    return { errors: { ...res }, status: response.status };
  }

  return redirect(
    `/contact/${params.id}/confirmation?q=${params.id}&v=${showLinks}`
  );
}
