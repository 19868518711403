export const BEDROOMS = [
  { label: "Any", value: "0" },
  { label: "1+", value: "1" },
  { label: "2+", value: "2" },
  { label: "3+", value: "3" },
  { label: "4+", value: "4" },
];

export const BATHROOMS = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
];

export const PRICES = [
  1000, 1200, 1300, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000,
  6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000, 20000, 30000, 50000, 100000,
  130000, 150000, 200000, 250000, 300000, 350000, 400000, 450000, 500000,
  550000, 600000, 650000, 700000, 750000, 800000, 850000, 900000, 1000000,
  1100000, 1250000, 1400000, 1500000, 1600000, 1700000, 1800000, 1900000,
  2000000, 2250000, 2500000, 2750000, 3000000, 3500000, 4000000, 5000000,
  10000000, 20000000,
];
